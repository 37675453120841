<template>
  <div class="content">
    <PageHeader :title="$t('wpp-component.template-component.title')" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12">
                  <div class="input-group">
                    <div class="">
                      <router-link
                        :to="'/whatsapp/templates/new'"
                        class="btn btn-success"
                        v-if="client.active"
                      >
                        <i class="icon dripicons-plus"></i>{{$t('sms.templates.lbl-create')}}
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="col-lg-9 col-md-9">
                  <div class="input-group" style="flex-direction: row-reverse;">
                    <div class="input-group-prepend">
                      <button class="btn btn-danger" @click="fetchTemplates(form.page)">
                        {{$t('generic-str.sort')}}
                      </button>
                      <select class="form-control" style="width: 120px;" v-model="form.sort">
                        <option selected value='id'>ID</option>
                        <option value="status">STATUS</option>
                        <option value="name">{{$tc('generic-str.name', 1)}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!templates.length && fetched"
                  class="text-center table-placeholder"
                >
                  <i
                    class="fas fa-envelope font-size-80"
                    v-tooltip.top="
                      $t('template-component.tooltip')
                    "
                  ></i>
                  <h5 class="card-title m-t-20">{{$t('sms.templates.none')}}</h5>
                  <p>{{$t('sms.templates.lbl-register')}}</p>
                </div>
                <table v-if="fetched" class="table">
                  <thead v-if="templates.length" class="bg-light">
                    <tr>
                      <th scope="col" class="text-left" style="width: 90%">{{$tc('generic-str.name', 1)}}</th>
                      <th scope="col" class="text-left">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(list, i) in templates"
                      :key="list.id"
                    >
                      <td data-label="Nome: " class="text-left m-text-right" style="padding-left: 20px;">
                        {{ list.name }}
                      </td>
                      <td
                        data-label="Status: "
                        class="text-left m-text-right"
                      >
                        <span v-if="list.status == 'submitted'" class="badge badge-warning">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                        <span v-if="list.status == 'created'" class="badge badge-warning">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                        <span v-else-if="list.status == 'accepted'" class="badge badge-warning">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                        <span v-else-if="list.status == 'failed'" class="badge badge-danger">{{$t('generic-str.status.lbl-failure')}}</span>
                        <span v-else-if="list.status == 'rejected'" class="badge badge-danger">{{$t('generic-str.status.lbl-rejected')}}</span>
                        <span v-else-if="list.status == 'pending'" class="badge badge-warning">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                        <span
                          v-else-if="list.status == 'active'"
                          class="badge badge-success"
                        >{{$t('generic-str.status.lbl-active')}}</span>
                        <span
                          v-else-if="list.status == 'approved'"
                          class="badge badge-success"
                        >{{$t('generic-str.status.lbl-active')}}</span>
                      </td>
                      <td data-label="" class="text-right">
                        <div class="dropdown" style="margin-right: 10px">
                          <a
                            role="button"
                            class="dropdown-action"
                            :to="'#menu-dropdown-' + i"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="icon dripicons-gear text-success"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            :id="'menu-dropdown-' + i"
                            x-placement="bottom-end"
                            style=""
                          >
                            <a
                              class="dropdown-item"
                              @click="deleteTemplate(list.name)"
                              >{{$t('generic-str.remove')}}</a>
                            <a class="dropdown-item" @click="callPreview('preview-modal', list)">Preview</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetchTemplates" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <create-template-modal id="create-template-modal"/>
    <send-template-modal id="send-template-modal"/>
    <preview-modal id="preview-modal"/>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import WhatsAppService from '@/services/whatsapp.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/Pagination.vue';
import CreateTemplateModal from '@/components/whatsapp/CreateTemplateModal.vue';
import SendTemplateModal from '@/components/whatsapp/SendTemplateModal.vue';
import PreviewModal from '@/components/whatsapp/PreviewModal.vue';

export default {
  name: 'WhatsAppTemplates',
  components: {
    PageHeader,
    Pagination,
    CreateTemplateModal,
    PreviewModal,
    SendTemplateModal,
  },
  data() {
    return {
      isSending: false,
      fetched: false,
      templates: [],
      client: {},
      form: {
        page: 1,
        limit: 400,
        sort: 'id',
      },
      pages: 1,
    };
  },
  created() {
    this.fetch(1);
    this.$root.$on('template.submit', this.fetch);
  },
  methods: {
    callModal(id) {
      const data = {
        language: 'pt_BR',
        client_id: this.client.id,
        body_examples: [],
        buttons: [],
        category: 'Account Update',
        header: { type: 'TEXT', text: '' },
      };
      this.$root.$emit('show.modal', id, data);
    },
    callPreview(id, data) {
      console.log(data);
      this.$root.$emit('show.modal', id, data);
      this.$root.$emit('updated.previewwhats', data);
    },
    fetch() {
      Promise.all([
        WhatsAppService.getClient(),
      ])
        .then(
          (responses) => {
            console.log(responses);
            this.client = responses[0].data;
            this.form.client_id = this.client.id;
            this.fetchTemplates(1);
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          // this.fetched = true;
        });
    },
    deleteTemplate(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('template-component.warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetchedItens = false;
          Promise.all([
            WhatsAppService.deleteTemplate(id, this.form),
          ])
            .then(
              (responses) => {
                console.log(responses);
                this.fetchTemplates(1);
              },
              (error) => {
                this.content = error;
              },
            )
            .finally(() => {
              this.fetchedItens = true;
            });
        }
      });
    },
    fetchTemplates(page) {
      this.form.page = page;
      console.log(page);
      this.fetchedItens = false;
      this.fetched = false;
      Promise.all([
        WhatsAppService.getTemplates(this.form),
      ])
        .then(
          (responses) => {
            console.log('Templates');
            this.fetched = true;
            console.log(responses[0]);
            if (responses[0].data.data) {
              this.templates = responses[0].data.data.waba_templates;
              this.pages = Math.trunc(responses[0].data.data.total / 10);
            }
            this.fetchedItens = true;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetchedItens = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}
</style>
